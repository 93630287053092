import React, { createContext, useState } from "react";

export const PluginContext = createContext();

const PluginContextProvider = ({ children }) => {
  const [activePlugin, setActivePlugin] = useState("allPlugins");
  return (
    <PluginContext.Provider value={{ activePlugin, setActivePlugin }}>
      {children}
    </PluginContext.Provider>
  );
};

export default PluginContextProvider;
