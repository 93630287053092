exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plugins-alce-js": () => import("./../../../src/pages/plugins/alce.js" /* webpackChunkName: "component---src-pages-plugins-alce-js" */),
  "component---src-pages-plugins-canvas-master-js": () => import("./../../../src/pages/plugins/canvas-master.js" /* webpackChunkName: "component---src-pages-plugins-canvas-master-js" */),
  "component---src-pages-plugins-double-usm-js": () => import("./../../../src/pages/plugins/double-usm.js" /* webpackChunkName: "component---src-pages-plugins-double-usm-js" */),
  "component---src-pages-plugins-floating-adjustments-js": () => import("./../../../src/pages/plugins/floating-adjustments.js" /* webpackChunkName: "component---src-pages-plugins-floating-adjustments-js" */),
  "component---src-pages-plugins-js": () => import("./../../../src/pages/plugins.js" /* webpackChunkName: "component---src-pages-plugins-js" */),
  "component---src-pages-plugins-theme-switcher-js": () => import("./../../../src/pages/plugins/theme-switcher.js" /* webpackChunkName: "component---src-pages-plugins-theme-switcher-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

