import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import CCExt from "../icons/CCExt.svg";
import CCExtensions from "../icons/CCExtensions.svg";

const CCExtLogo = () => {
  const breakpoints = useBreakpoint();
  const divStyleMobile = {
    minHeight: `25px`,
    height: `25px`,
    width: `25px`,
    minWidth: `25px`,
    // marginTop: `5px`,
    marginRight: `5px`,
    // border: `1px solid gray`,
  };
  const divStyleDesktop = {
    height: `35px`,
    width: `140px`,
    minHeight: `35px`,
    minWidth: `140px`,
    // marginTop: `5px`,
    marginRight: `20px`,
    // border: `1px solid gray`,
  };
  return breakpoints.tabletLandscapeUp === true ? (
    <CCExtensions style={divStyleDesktop} />
  ) : (
    <CCExt style={divStyleMobile} />
  );
};

export default CCExtLogo;
