import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const DEFAULT_THEME = "dark";
  // const [colorScheme, setColorScheme] = useState("dark");
  // console.log("colorscheme", localStorage.getItem("theme"));
  const [colorScheme, setColorScheme] =
    typeof window !== "undefined"
      ? useState(
          localStorage.getItem("theme") === "dark" ||
            localStorage.getItem("theme") === "light"
            ? localStorage.getItem("theme")
            : DEFAULT_THEME
        )
      : useState("dark");

  return (
    <ThemeContext.Provider value={{ colorScheme, setColorScheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
