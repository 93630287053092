import React, { useContext } from "react";

import { ActionButton } from "@adobe/react-spectrum";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Light from "@spectrum-icons/workflow/Light";
import Moon from "@spectrum-icons/workflow/Moon";

import { ThemeContext } from "../contexts/ThemeContext";

// Using Context in Functional Components
// https://www.taniarascia.com/using-context-api-in-react/

const ThemeSwitcher = () => {
  const { colorScheme, setColorScheme } = useContext(ThemeContext);
  const breakpoints = useBreakpoint();
  const toggleColorScheme = () => {
    if (typeof window !== "undefined") {
      localStorage.setItem("theme", colorScheme === "light" ? "dark" : "light");
    }
    setColorScheme(colorScheme === "light" ? "dark" : "light");
  };
  const label =
    colorScheme === "dark" ? "Switch to light theme" : "Switch to dark theme";
  const iconSize = breakpoints.phoneOnly === true ? `XS` : `S`;
  return (
    <ActionButton aria-label={label} isQuiet onPress={toggleColorScheme}>
      {colorScheme === "dark" ? (
        <Light size={iconSize} />
      ) : (
        <Moon size={iconSize} />
      )}
    </ActionButton>
  );
};

export default ThemeSwitcher;
