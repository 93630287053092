// React
import PropTypes from "prop-types";
import React, { useContext } from "react";
// Gatsby
import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
// Spectrum
import { Tabs, TabList, Item } from "@react-spectrum/tabs";
import { Flex, View } from "@adobe/react-spectrum";
// Components
import styled from "styled-components";
import { TabsContext } from "../contexts/TabsContext";
import { PluginContext } from "../contexts/PluginContext";

import CCExtLogo from "./logo";
import ThemeSwitcher from "./ThemeSwitcher";

const HeaderWrapperStyles = styled.div`
  position: fixed;
  border-bottom: 2px solid var(--spectrum-global-color-gray-300);
  /* background-color: var(--spectrum-global-color-gray-75); */
  min-width: 320px;
  width: 100%;
  z-index: 1000;

  .tabs-class span {
    cursor: pointer;
  }
`;

const Header = ({ siteTitle }) => {
  const { activeTab, setActiveTab } = useContext(TabsContext);
  const { setActivePlugin } = useContext(PluginContext);

  const breakpoints = useBreakpoint();

  const navigateToPage = evt => {
    if (evt === "plugins") {
      setActivePlugin("allPlugins");
    }
    setActiveTab(evt);
    // console.log("Navigated to...", evt);
    navigate(`/${evt === "home" ? "" : evt}`);
  };

  return (
    <HeaderWrapperStyles>
      <View
        backgroundColor="gray-75"
        height={
          breakpoints.tabletLandscapeUp === true ? `size-800` : `size-500`
        }
      >
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginX={breakpoints.phoneOnly === true ? `size-100` : `size-400`}
        >
          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div
            onClick={() => navigateToPage("home")}
            onKeyPress={() => navigateToPage("home")}
            style={{ display: `flex`, alignItems: `center`, cursor: `pointer` }}
            role="link"
            aria-label="Home link"
          >
            <CCExtLogo ariaLabel={siteTitle} />
          </div>
          <Tabs
            height={
              breakpoints.tabletLandscapeUp === true ? `size-800` : `size-500`
            }
            aria-label="Main Menu"
            marginX={breakpoints.phoneOnly === true ? `size-50` : `size-200`}
            selectedKey={activeTab}
            onSelectionChange={navigateToPage}
            id="main-tab-menu"
            isQuiet="true"
            UNSAFE_className="tabs-class"
          >
            <TabList height={
              breakpoints.tabletLandscapeUp === true ? `size-800` : `size-500`
            }>
              <Item title="Home" key="home" aria-label="Home" />
              <Item title="Plugins" key="plugins" aria-label="Plugins" />
              <Item title="Support" key="support" aria-label="Support" />
            </TabList>
          </Tabs>
          <ThemeSwitcher />
        </Flex>
      </View>
    </HeaderWrapperStyles>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
