import React, { useContext } from "react";
import { SSRProvider, Provider, defaultTheme } from "@adobe/react-spectrum";
import { ThemeContext } from "../contexts/ThemeContext";

const SpectrumProvider = ({ children }) => {
  const { colorScheme } = useContext(ThemeContext);
  return (
    <SSRProvider>
      <Provider theme={defaultTheme} colorScheme={colorScheme}>
        {children}
      </Provider>
    </SSRProvider>
  );
};

export default SpectrumProvider;
