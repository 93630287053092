/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
// STYLES
import "normalize.css";
import "./src/styles/Global.css";

// https://github.com/nerdyman/react-compare-slider/issues/25
// TL;DR
// npm install resize-observer-polyfill --save (or --save-dev?!)
// add the following:
// import ResizeObserver from 'resize-observer-polyfill';
// to:
// node_modules/react-compare-slider/dist/react-compare-slider.esm.js

import Layout from "./src/layout/layout";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};
