import React from "react";
import styled from "styled-components";
import { Link as SpectrumLink } from "@adobe/react-spectrum";

const FooterStyled = styled.div`
  width: 100%;
  height: 40px;
  background-color: var(--spectrum-global-color-gray-75);
  color: var(--spectrum-global-color-gray-300);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 80%;
  z-index: 30;
  position: relative;
  .links {
    opacity: 0.5;
  }
  @media only screen and (min-width: 600px) {
    font-size: 100%;
    height: 44px;
  }
`;

// https://www.iubenda.com/privacy-policy/7877383/cookie-policy
// https://www.iubenda.com/privacy-policy/7877383/
const Footer = () => (
  <FooterStyled>
    <div className="links">
      <SpectrumLink>
        <a
          href="https://www.iubenda.com/privacy-policy/7877383/"
          rel="nofollow"
        >
          Privacy
        </a>
      </SpectrumLink>
      &nbsp;-&nbsp;
      <SpectrumLink>
        <a
          href="https://www.iubenda.com/privacy-policy/7877383/cookie-policy"
          rel="nofollow"
        >
          Cookies
        </a>
      </SpectrumLink>
      {/* &nbsp;-&nbsp;
      <SpectrumLink>
        <a
          href="https://www.iubenda.com/privacy-policy/7877383/cookie-policy"
          rel="nofollow"
        >
          Mail
        </a>
      </SpectrumLink> */}
    </div>
  </FooterStyled>
);

export default Footer;
