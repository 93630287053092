/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "../components/Header";

import SpectrumProvider from "../components/SpectrumProvider";
import ThemeContextProvider from "../contexts/ThemeContext";
import TabsContextProvider from "../contexts/TabsContext";
import PluginContextProvider from "../contexts/PluginContext";
import Footer from "../components/Footer";

import "@fontsource/source-sans-pro/300.css";
import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/400-italic.css";
import "@fontsource/source-sans-pro/700.css";

const AppContainerStyled = styled.div`
  min-width: 320px;
  width: 100%;
  height: 100vh;
  overflow-y: auto !important;
  position: static !important;
  main {
    overscroll-behavior: none;
    /* Calculating the viewport height minus the footer */
    min-height: calc(100vh - 40px);
    padding-top: calc(var(--spectrum-global-dimension-size-500) + 2px);

    @media only screen and (min-width: 600px) {
      min-height: calc(100vh - 44px);
    }

    @media only screen and (min-width: 900px) {
      padding-top: calc(var(--spectrum-global-dimension-size-800) + 2px);
    }
  }
`;
// Location is interesting, you can pass it down to any component (unclear how to
// pass it to main though) and can be used to drive behaviour based on route, see:
// https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
const Layout = ({ children, location }) => (
  <ThemeContextProvider>
    <PluginContextProvider>
      <TabsContextProvider>
        <SpectrumProvider>
          <AppContainerStyled>
            <Header siteTitle="CC Extensions" location={location} />
            <main>{children}</main>
            <Footer />
          </AppContainerStyled>
        </SpectrumProvider>
      </TabsContextProvider>
    </PluginContextProvider>
  </ThemeContextProvider>
);
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
